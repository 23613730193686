<template>
  <div>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="roomId"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/room/filter.diti"
      :requestOptions="requestOptions"
    >
      <template #query>
        <a-form-model-item label="关键词">
          <a-select v-model="queryFormState.companyId">
            <a-select-option
              v-for="item in companyList"
              :key="item.companyId"
              :value="item.companyId"
            >
              {{ item.companyName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </template>
    </PagingQueryPage>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage/diti.vue";
import { companyAll } from "@/apis/company";
import { roomStatusOptions } from "@/config/enum";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      queryFormState: {
        companyId: "0",
      },
      requestOptions: {},
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "ID",
          dataIndex: "roomId",
          width: 200,
          align: "center",
        },
        {
          title: "直播间名称",
          dataIndex: "roomName",
          width: 200,
          align: "center",
        },
        {
          title: "状态",
          dataIndex: "status",
          width: 200,
          align: "center",
          customRender: (text) => {
            return roomStatusOptions.find((r) => r.value === text)
              ? roomStatusOptions.find((r) => r.value === text).label
              : "";
          },
        },
        {
          title: "备注",
          dataIndex: "remark",
          align: "center",
        },
      ],
      pagination: {},
      companyList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      companyAll({ keyword: "", numOfPage: "1", rowsInPage: "200" }).then(
        (res) => {
          const {
            data: { resList },
            code,
          } = res.data;
          if (code === "200") {
            this.companyList = resList;
          }
        }
      );
    },
  },
};
</script>

<style></style>
